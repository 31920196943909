.account-action:hover {
  background-color: #f5f5f5;
   /* Or any desired color */
  transition: background-color 0.3s ease;
  }
  
  .account-action {
    height: 60px;
    transition: background-color 0.3s ease;
  }
  
 