/* styles.css */
:root {
  --title-color: rgb(50, 104, 136);
}
.title-color {
  color: var(--title-color);
}

body {
  /* font-size: 0.75rem; */
  color: black;
}

body {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #333333;
  background-color: #FFFFFF;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #222222;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #444444;
  line-height: 1.8;
}

b,
strong {
  font-weight: bold;
}

.social-btn {
  font-size: 0.875rem;
  padding-left: 10px;
}

.search-wrapper .chip {
  white-space: inherit;
}

.MuiTable-root {
  width: 99% !important;
  font-size: 0.85rem;
  position: relative;
  z-index: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.overview-img {
  width: 12rem;
  height: 8rem;
  object-fit: cover;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

#root {
  background-color: white;
}

.form-control,
.form-control:focus {
  color: black;
  font-weight: 600;
}

.btn-hover-gray:hover {
  background-color: #ebedf0;
}

.bg-gray-50 {
  background-color: #ebedf0;
}

/* .btn-secondary:hover {
  background-color: blue;
} */

.color-blue {
  color: blue;
}

.MuiInputBase-input {
  color: #495057 !important;
  font-weight: 400 !important;
}

.input-group-text {
  color: black;
  background-color: #f3f3f3;
  font-weight: 600;
}

img.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 3.5+, IE10 */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
  -webkit-transition: all 0.6s ease;
  /* Fade to color for Chrome and Safari */
  -webkit-backface-visibility: hidden;
  /* Fix for transition flickering */
}

img.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

/* .form-group .form-control {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}
.form-group {
  position: relative;
}
.form-group .form-control-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 1rem;
  height: 1rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  cursor: pointer;
  right: 6px;
  top: 12px;
} */

.btn-right-icon {
  background-color: transparent;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-left: none;
  border-top: 1px solid #cad1d7;
  border-bottom: 1px solid #cad1d7;
  border-right: 1px solid #cad1d7;
}

.rigth-top-icon {
  margin-right: 10px;
  top: 20px;
  right: 0px;
  position: absolute;
  cursor: pointer;
}

.social-actions {
  padding-left: 20px;
}

.navbar-brand-img {
  max-height: 15rem !important;
  width: 15rem !important;
}

label {
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

@media (max-width: 575.98px) {
  .social-btn {
    font-size: 0.75rem;
    padding: 0;
  }

  .social-actions {
    padding-left: 0px;
    text-align: center !important;
  }

  .MuiStepper-root {
    display: flow-root !important;
  }

  .navbar-brand-img {
    max-height: 3rem !important;
    width: 10rem !important;
  }
}

@media (max-width: 767.98px) {}

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }
}

@media (max-width: 1199.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }
}

.hover-background:hover {
  background-color: #f5f5f5; /* Or any desired color */
  transition: background-color 0.3s ease;
}

.chip-badge {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.chip-default {
  background-color: #d3d3d3; /* Gray color for unselected badges */
  color: #333;
}

.chip-selected {
  background-color: #007bff; /* Blue color for selected badges */
  color: white;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  color: #666;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;

  &.dropdown-toggle:after {
    display: none;
  }

  >i {
    margin-right: 12px;
  }

  &.btn-icon,
  &.drp-icon {
    /* width: 45px; */
    height: 45px;
    padding: 10px 12px;

    >i {
      margin-right: 0;
    }
  }

  &.drp-icon {
    &.dropdown-toggle:after {
      display: none;
    }

    +.dropdown-menu {
      margin-left: -10px;
    }
  }

  &:active,
  &:focus {
    box-shadow: none;
  }

  &-square {
    border-radius: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.55;
  }

  &-rounded {
    border-radius: 30px;
  }
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.disabled-link {

  cursor: not-allowed;
  /* Shows a "not allowed" cursor */
}

.disabled-icon {
  filter: grayscale(100%);
  opacity: 0.5;
}


.black-a90 {
  color: rgb(0 0 0 / .9);
}

.black-a75 {
  color: rgb(0 0 0 / .75);
}

.black-a60 {
  color: rgb(0 0 0 / .6);
}

.navbar-vertical .navbar-nav {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.navbar-collapse {
  margin-left: -12px !important;
}

.notif-badge {
  position: relative;
  top: -5px;
  right: 10px;
  background-color: #f44236;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
}



.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  width: 30%;
  height: 5px;
  background-color: grey;
}

.step.active {
  background-color: #178ADD;
}
/* ----- Pricing Section Styling Starts ----- */
.pricing-section {
  width: 100%;
  height: 100%;
  background: #f4f7fa;
}

.pricing-intro {
  padding-bottom: 5px;
}

.pricing-intro h2 {
  font-size: 28px;
  color: #111111;
  font-weight: 600;
  line-height: 1.4;
}

.pricing-intro p {
  font-size: 15px;
  color: #303030;
  line-height: 1.4;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pricing-section .table-left,
.pricing-section .table-right {
  padding: 20px 20px 50px 20px;
  margin: 0 auto;
  margin-bottom: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #efefef;
  max-width: 400px;
}

.table-left .icon,
.table-right .icon {
  padding: 50px 50px 40px 50px;
}

.table-left .icon img,
.table-right .icon img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.table-left .pricing-details span,
.table-right .pricing-details span {
  display: inline-block;
  font-size: 40px;
  font-weight: bolder;
  color: #808080;
}

.table-left .pricing-details h2,
.table-right .pricing-details h2 {
  font-size: 25px;
  font-weight: 600;
  color: #505050;
  margin-bottom: 20px;
}

.table-left .pricing-details p,
.table-right .pricing-details p {
  font-size: 14px;
  color: #505050;
  letter-spacing: 1px;
  line-height: 1.4;
}

.table-left .pricing-details ul,
.table-right .pricing-details ul {
  margin-top: 30px;
  margin-bottom: 50px;
}

.table-left .pricing-details li,
.table-right .pricing-details li {
  font-size: 14px;
  font-weight: 400;
  color: #505050;
  line-height: 1.4;
  margin-bottom: 10px;
}

.pricing-section .table-left:hover,
.pricing-section .table-right:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-container {
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.feature-comment {
  margin-left: 8px; /* Adjust the spacing as needed */
}

/* ----- Pricing Section Styling Ends ----- */

.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  font-size: 0.65rem;
  text-transform: none !important;
  letter-spacing: 1px;
  border-bottom: 1px solid #e9ecef;
}

.phone-button {
  transition: all 0.3s ease-in-out;
  font-weight: bold;
}

.phone-button:hover {
  background-color: rgb(111, 174, 222);
  color: white;
}

.phone-number {
  display: inline-block;
  animation: fadeIn 0.5s ease-in-out;
  font-weight: bold;
  /* color: #007bff; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.delete-icon {
  color: #dc3545;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.2s;
}

.delete-icon:hover {
  transform: scale(1.2);
}
