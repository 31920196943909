.notification-custom {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
  cursor: pointer;


  .noti-head {
    border-bottom: 1px solid #f1f1f1;
    padding: 15px 20px;

    a {
      text-decoration: underline;
      font-size: 13px;
    }
  }

  .noti-body {
    padding: 0;
    min-height: 160px;

    .empty-table {
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center
    }

    //   img {
    //     width: 40px;
    //     margin-right: 20px;
    //   }

    li {
      padding: 15px 20px;
      transition: all 0.3s ease-in-out;
      color: #2b3f64;

      &.n-title {
        padding-bottom: 0;

        p {
          margin-bottom: 5px;
        }
      }



      p {
        margin-bottom: 5px;
        font-size: 13px;

        .text-adjust {
          display: inline-block;
          max-width: 215px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        strong {
          font-weight: bold;
          color: #3f4d67;
        }
      }

      .n-time {
        font-size: 80%;
        float: right;
      }
    }
  }

  .notif-icon {
    height: 17px;
    margin-right: 5px;
    fill: #3f4d67
  }

  .noti-footer {
    border-top: 1px solid #f1f1f1;
    padding: 15px 20px;
    text-align: center;

    a {
      text-decoration: underline;
      font-size: 13px;
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      padding: 20px 15px;
    }
  }
}

.notification-container {
  width: 350px !important;
}