.plan-box {
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #fafafa;
    margin-bottom: 30px;
  }
  
  .plan-title {
    color: #6b7280;
    font-size: 14px;
    font-weight: bold;
  }
  
  .plan-price {
    font-size: 20px;
    font-weight: bold;
    margin: -5px 0;
  }